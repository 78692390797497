<template>
  <a-layout class="main-container">
    <banner />
    <div class="public-container">
      <div class="public-title">2024年1月至8月 新增会员名单公示</div>
      <div class="public-divider"></div>
      <a-table
        class="table"
        :columns="tblColumns"
        :data-source="tblData"
        :loading="loading"
        :pagination="false"
        :rowKey="(record, index) => index"
      >
        <template slot="no" slot-scope="text, record">
          <div class="no">{{ record.no }}</div>
        </template>
      </a-table>
    </div>
  </a-layout>
</template>

<script>
import Banner from "@/views/member/components/Banner.vue";

const tblColumns = [
  {
    title: "会员编号",
    scopedSlots: { customRender: "no" },
    width: "40%"
  },
  {
    title: "姓名",
    dataIndex: "realName",
    width: "30%"
  },
  {
    title: "省市",
    dataIndex: "area",
    width: "30%"
  }
];
export default {
  components: { Banner },
  data() {
    return {
      tblColumns,
      tblData: [
        { no: "中少造字证（1000）", realName: "李煜啼", area: "浙江省杭州市" },
        { no: "中少造字证（1001）", realName: "黄敏文", area: "浙江省杭州市" },
        { no: "中少造字证（1002）", realName: "钟秀黎", area: "浙江省杭州市" },
        { no: "中少造字证（1003）", realName: "章文川", area: "浙江省杭州市" },
        { no: "中少造字证（1004）", realName: "郑溟", area: "浙江省杭州市" },
        { no: "中少造字证（1005）", realName: "刘晓波", area: "浙江省杭州市" },
        { no: "中少造字证（1006）", realName: "林筱熙", area: "浙江省杭州市" },
        { no: "中少造字证（1007）", realName: "朱敏", area: "浙江省杭州市" },
        { no: "中少造字证（1008）", realName: "陈国梁", area: "浙江省杭州市" }
      ],
      loading: false
    };
  }
};
</script>

<style scoped lang="less">
.main-container {
  min-height: 100vh;
  background-color: #f1f2f5;
  padding-top: 72px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .public-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 58px;
    .public-title {
      font-weight: 600;
      font-size: 30px;
      color: #000000;
      letter-spacing: 2px;
    }
    .public-divider {
      width: 520px;
      height: 3px;
      background: #000000;
    }
    .table {
      width: 939px;
      margin: 58px 0 104px 0;
      /deep/ .ant-table-thead > tr > th {
        background-color: rgba(0, 0, 0, 0.02);
        font-weight: 500;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.85);
      }
      /deep/ .ant-table-thead > tr:first-child > th:first-child {
        padding: 16px 16px 16px 56px;
      }
      /deep/ .ant-table-tbody > tr > td {
        background-color: white;
      }
      .no {
        box-sizing: border-box;
        padding-left: 40px;
      }
    }
  }
}
</style>
